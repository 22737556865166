/**
 * @Author: Anandp
 * @Date:   2024-12-23 15:31:35
 * @Last Modified by:   Anandp
 * @Last Modified time: 2025-03-28 10:51:11
 */
import axios from "axios";
import { Url } from "src/config";

//Common api

axios.interceptors.request.use(
  (config) => {
    config.headers["access-token"] = `${sessionStorage.getItem('accessToken')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const commonApiService = {
  /**
   * used to get Sidebar menus
   * @returns SideBar menus
   */
  getSideBarMenus: async (id) => {
    try {
      const response = await axios.get(`${Url}/module/common/getsidebarmenu?id=${id}`);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getDashboardData: async (id) => {
    try {
      const response = await axios.get(`${Url}/module/common/getdashboarddata`);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getDashboardQuestion: async (accessToken) => {
    try {
      const response = await axios.get(`${Url}/module/getDashboardQuestion`,{
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  insertQuestion: async (accessToken,body) => {
    try {
      const response = await axios.post(`${Url}/module/insertQuestion`,body,{
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  deleteQuestion: async (accessToken) => {
    try {
      const response = await axios.get(`${Url}/module/deleteQuestion`,{
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
};

export default commonApiService;
